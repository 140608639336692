import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState } from "./common";
import { getAuthData, putAuthData } from "../../../services/request";
import { createOptionsArr } from "../ezEditor/common";

export const fetchPartLevelInformation = createAsyncThunk("partLevel/fetchPartLevelInformation", async (assignmentId, thunkAPI) => {
  try {
    const url = `${process.env.REACT_APP_USER_TM_API_URL}/publish/api/publish-allocate/admin/v1/assignment/part-info/${assignmentId}`;
    const response = await getAuthData(url);
    if (!response.success) {
      throw new Error("Failed to fetch part level information");
    }

    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const reallocateAssign = createAsyncThunk("partLevel/reallocateAssign", async (args, thunkAPI) => {
  try {
    const url = `${process.env.REACT_APP_USER_TM_API_URL}/publish/api/publish-allocate/admin/v1/reallocate/${args.assignmentId}`;
    const data = args.data;
    const response = await putAuthData(url, data);
    if (!response.success) {
      throw new Error("Failed to fetch part level information");
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getLevelInformation = createAsyncThunk("level-info/getLevelInformation", async (assignmentId, thunkAPI) => {
  try {
    const part_url = `${process.env.REACT_APP_USER_TM_API_URL}/publish/api/publish-allocate/admin/v1/assignment/part-info/${assignmentId}`;
    const slide_url = `${process.env.REACT_APP_USER_TM_API_URL}/publish/api/publish-allocate/admin/v1/assignment/page-info/${assignmentId}`;

    // Fetching part and slide data concurrently
    const [part_res, slide_res] = await Promise.all([getAuthData(part_url), getAuthData(slide_url)]);

    if (!part_res.success || !slide_res.success) {
      throw new Error("Failed to fetch part level information");
    }

    return { part: part_res, slide: slide_res.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getEditorTrackerAssignOptions = createAsyncThunk(
  "editor-tm-data/getEditorTrackerAssignOptions",
  async ({ rejectWithValue }) => {
    let url = `${process.env.REACT_APP_USER_TM_API_URL}/publish/api/publish-allocate/admin/v1/assignment/list`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        return createOptionsArr(res.data);
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

const ezEditorTrackerSlice = createSlice({
  name: "ezEditorTrackerData",
  initialState,
  reducers: {
    resetEditorTracker: (state) => {
      state.loading = initialState.loading;
      (state.error = initialState.error),
        (state.part = initialState.part),
        (state.slide = initialState.slide),
        (state.user_tm_data = initialState.user_tm_data);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEditorTrackerAssignOptions.pending, (state) => {
        state.user_tm_data.workspace_assgn_list.loading = true;
      })
      .addCase(getEditorTrackerAssignOptions.fulfilled, (state, { payload }) => {
        state.user_tm_data.workspace_assgn_list.loading = false;
        state.user_tm_data.workspace_assgn_list.data = payload;
      })
      .addCase(getEditorTrackerAssignOptions.rejected, (state, action) => {
        state.user_tm_data.workspace_assgn_list.loading = false;
        state.user_tm_data.workspace_assgn_list.error = action.message;
      })
      .addCase(getLevelInformation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLevelInformation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.part.partLevelInformation = payload.part;
        state.slide.slidePageLevelInformation = payload.slide;
      })
      .addCase(getLevelInformation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(fetchPartLevelInformation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.part.partLevelInformation = payload;
      });
  },
});

export const { resetEditorTracker } = ezEditorTrackerSlice.actions;

export default ezEditorTrackerSlice.reducer;
