import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { fetchPartLevelInformation, reallocateAssign } from "../../../store/features/ezEditorTracker/ezEditorTrackerSlice";

import "./PartLevelInformation.scss";
import SlidePageLevelInformation from "../SlidePageLevelInformation/SlidePageLevelInformation";
import Select from "react-select";
import { customSelectStyles, customSelectTheme } from "../../../utils/Components/InputComps/DropdownSelectInput/customSelectInputStyles";
import formatDate from "../../../utils/functions/formatDate";
import toast from "react-hot-toast";

const PartLevelInformation = ({ assignmentId }) => {
  const dispatch = useDispatch();
  const partLevelInformation = useSelector((state) => state.editorTrackerData.part.partLevelInformation);
  const loading = useSelector((state) => state.editorTrackerData.loading);
  const [selectedRows, setSelectedRows] = useState([]);
  const [changedValues, setChangedValues] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [requestData, setRequestData] = useState({});
  const [assignId, setAssignId] = useState(assignmentId);
  const singleValueStyles = {
    color: "var(--white)",
    backgroundColor: "var(--prime-blue)",
    borderRadius: "2px",
    padding: "0.5vh",
    width: "fit-content",
  };

  const availableTR = partLevelInformation?.available_translators?.map((item) => ({
    label: item.resource_email,
    value: item.user_id,
  }));

  const availablePRorQA = partLevelInformation?.available_proofreaders?.map((item) => ({
    label: item.resource_email,
    value: item.user_id,
  }));

  useEffect(() => {
    if (assignmentId) {
      dispatch(fetchPartLevelInformation(assignmentId));
    }
  }, [assignmentId, dispatch]);

  const handleEdit = (index, row) => {
    if (!row.is_allocated || row.is_submitted) {
      return toast.error("Cannot reallocate, it's already submiited");
    }
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = [...prevSelectedRows];
      newSelectedRows[index] = true;
      return newSelectedRows;
    });

    setChangedValues((prevChangedValues) => {
      const newChangedValues = [...prevChangedValues];
      newChangedValues[index] = { label: row.resource_email, value: row.user_id };
      return newChangedValues;
    });
    const assignment_id = row.part_id.split("_")[0];
    setAssignId(assignment_id);
    setSaveDisabled(false); // Enable save button when a dropdown is edited
  };

  const handleDropdownChange = (option, index, row) => {
    setChangedValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = option;
      return newValues;
    });

    setRequestData(
      (prev) =>
        (prev = {
          ...prev,
          [row.part_id]: { ...prev[row.part_id], [row.role]: { resource_id: option.value, deadline: formatDate(row.deadline) } },
        }),
    );
    setSaveDisabled(false); // Enable save button when a dropdown is edited
  };

  const handleSave = () => {
    // Your save logic here
    if (requestData) {
      dispatch(reallocateAssign({ assignmentId: assignId, data: requestData })).then((response) => {
        console.log(response);
        if (response.meta.requestStatus == "fulfilled") {
          setSaveDisabled(true); // Disable save button after saving
          dispatch(fetchPartLevelInformation(assignId));
          setSelectedRows([]);
          setChangedValues([]);
          setRequestData({});
        }
      });
    }
  };

  if (!partLevelInformation?.data?.length) {
    return <div className="part_level_table_data_container_heading">No Data Available ! Please Select Workspace Id</div>;
  }

  return (
    <div className="part_level_table_data_container">
      <div className="part_level_table_data_title">
        <h2 className="part_level_heading">Part Level Information</h2>
        <button className={`${saveDisabled ? "disabled-btn" : "edit-btn"}`} onClick={handleSave} disabled={saveDisabled}>
          Save
        </button>
      </div>

      {!loading && (
        <div className="part_level_table_scrollable">
          <Table className="part_level_information_table">
            <thead className="part_level_cols_data">
              <tr>
                <th>Part ID</th>
                <th>Role</th>
                <th>Resource Email</th>
                <th>% Complete</th>
                <th>% Submitted</th>
                <th>Reallocate</th>
              </tr>
            </thead>
            <tbody className="part_level_table_body">
              {partLevelInformation.data.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>{row.part_id || "-"}</td>
                    <td>{row.role || "-"}</td>
                    <td>
                      {selectedRows[index] ? (
                        <Select
                          id={`workspaceDropdown-${index}`}
                          className="select-container"
                          value={changedValues[index]}
                          options={row.role === "TR" ? availableTR : availablePRorQA}
                          onChange={(option) => handleDropdownChange(option, index, row)}
                          theme={customSelectTheme}
                          placeholder=""
                          styles={{
                            ...customSelectStyles,
                            control: (provided) => ({
                              ...provided,
                              width: "10vw",
                            }),
                            singleValue: (base) => ({
                              ...base,
                              ...singleValueStyles,
                            }),
                          }}
                        />
                      ) : (
                        row.resource_email || "-"
                      )}
                    </td>

                    <td>{row.completed_percentage ? `${row.completed_percentage}%` : "-"}</td>
                    <td>{row.submitted_percentage ? `${row.submitted_percentage}%` : "-"}</td>
                    <td>
                      {!row.is_allocated ? (
                        "-"
                      ) : (
                        <button
                          disabled={selectedRows[index]}
                          className={`${selectedRows[index] || !row.is_allocated || row.is_submitted ? "disabled-btn" : "edit-btn"}`}
                          onClick={() => handleEdit(index, row)}
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
      <SlidePageLevelInformation />
    </div>
  );
};

export default PartLevelInformation;
