import React, { createContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import BodyLayout from "./layout/BodyLayout";

import { EzSearch } from "./pages/composer";
import { HistorySection, SearchInfoComp } from "./features/composer";
import Login from "../src/features/Login/Login";
import CountHistory from "./features/Count/CountHIstory/CountHistory";
import CountUpload from "./features/Count/CountUpload/CountUpload";
import CountInfo from "./features/Count/CountInfo/CountInfo";
import FlipUpload from "./features/Flip/FlipUpload/FlipUpload";
import FlipHistory from "./features/Flip/FlipHistory/FlipHistory";
import EditorAssignmentTracker from "./features/EditorAssignmentTracker/EditorAssignmentTracker";
// import TranslateUpload from "./features/Translate/TranslateUpload.js/TranslateUpload";
import EditorUpload from "./features/Editor/EditorUpload/EditorUpload";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./utils/functions/ProtectedRoute";
import EditorUserTMUpload from "./features/EditorUserTM/EditorUserTMUpload/EditorUserTMUpload";

export const FileContext = createContext();
export const CountFileInfoContext = createContext();

const AppRoutes = () => {
  const [files, setFiles] = useState([]);
  const [countFileData, setCountFileData] = useState(null);

  return (
    <FileContext.Provider value={{ files, setFiles }}>
      <CountFileInfoContext.Provider value={{ countFileData, setCountFileData }}>
        {/* //*This AuthProvider is implemented to protect routes so user is not redirect to any component wIthout authentication */}
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />

            <Route path="/" element={<BodyLayout />}>
              {/* //* Count Tool Routes */}
              <Route path="/ez_count" element={<ProtectedRoute element={<CountUpload />}></ProtectedRoute>}></Route>
              <Route path="/ez_count_info" element={<ProtectedRoute element={<CountInfo />}></ProtectedRoute>}></Route>
              <Route path="/ez_count_info/:assignment_id" element={<ProtectedRoute element={<CountInfo />}></ProtectedRoute>}></Route>
              <Route path="/ez_count/history" element={<ProtectedRoute element={<CountHistory />}></ProtectedRoute>}></Route>

              {/* //* Translate Tool Routes */}
              {/* <Route path="/ez_translate" element={<ProtectedRoute element={<TranslateUpload />}></ProtectedRoute>}></Route> */}

              {/* //* Flip Tool Routes */}
              <Route path="/ez_flip" element={<ProtectedRoute element={<FlipUpload />}></ProtectedRoute>}></Route>
              <Route path="/ez_flip/history" element={<ProtectedRoute element={<FlipHistory />}></ProtectedRoute>}></Route>

              {/* //* Search Tool Routes */}
              <Route path="/ez_search" element={<ProtectedRoute element={<EzSearch />}></ProtectedRoute>}></Route>
              <Route path="/ez_search/history" element={<ProtectedRoute element={<HistorySection />}></ProtectedRoute>}></Route>
              <Route path="/ez_search/:assignment_id" element={<ProtectedRoute element={<SearchInfoComp />}></ProtectedRoute>}></Route>

              {/* //* Editor Tool Routes */}
              <Route path="/ez_editor" element={<ProtectedRoute element={<EditorUpload />}></ProtectedRoute>}></Route>
              <Route path="/ez_editor_user_tm" element={<ProtectedRoute element={<EditorUserTMUpload />}></ProtectedRoute>}></Route>
              <Route
                path="/ez_editor_assignment_tracker"
                element={<ProtectedRoute element={<EditorAssignmentTracker />}></ProtectedRoute>}
              ></Route>
            </Route>
          </Routes>
        </AuthProvider>
      </CountFileInfoContext.Provider>
    </FileContext.Provider>
  );
};

export default AppRoutes;
