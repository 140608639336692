import { configureStore } from "@reduxjs/toolkit";
import fetchHistoryDataSlice from "./features/ezSearchSlices/fetchHistorySlice/fetchHistorySlice";
import fileContextSlice from "./features/ezSearchSlices/fileContextSlice/fileContextSlice";
import filterDataSlice from "./features/ezSearchSlices/filterDataSlice/filterDataSlice";
import searchFormDataSlice from "./features/ezSearchSlices/searchFormDataSlice/searchFormDataSlice";
import wordCountDataSlice from "./features/ezSearchSlices/wordCountDataSlice/wordCountDataSlice";
import ezFlipSlice from "./features/ezFlipSlice/ezFlipSlice";
import countDataSlice from "./features/ezCountSlice/ezCountSlice";
import utilSlice from "./features/utilSlice/utilSlice";
import editorDataSlice from "./features/ezEditor/ezEditorSlice";
import editorUserTMDataSlice from "./features/ezEditorUserTM/ezEditorUserTMSlice";
import editorTrackerSlice from "./features/ezEditorTracker/ezEditorTrackerSlice";

const store = configureStore({
  reducer: {
    // *** EZ Count Data *** //
    countData: countDataSlice,
    // *** EZ Flip Data *** //
    flipData: ezFlipSlice,

    // *** EZ Search Data *** //
    fetchHistoryData: fetchHistoryDataSlice,
    filterData: filterDataSlice,
    wordCountData: wordCountDataSlice,
    fileContext: fileContextSlice,
    searchFormData: searchFormDataSlice,

    //  *** UTILS DATA *** //
    utilData: utilSlice,

    // *** EZ Editor Data *** //
    editorData: editorDataSlice,
    editorUserTMData: editorUserTMDataSlice,

    editorTrackerData: editorTrackerSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
