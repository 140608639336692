import "./MenuContent.scss";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  handleSearchMainAccess,
  resetStateData,
  setActiveMenu,
} from "../../../../store/features/ezSearchSlices/searchFormDataSlice/searchFormDataSlice";
import { ACTIVE_MENU } from "../../../../utils/namespaces/ACTIVE_TAB";
import { useEffect } from "react";
import { handleCountMainAccess, resetUserTab } from "../../../../store/features/ezCountSlice/ezCountSlice";
import { resetutilData } from "../../../../store/features/utilSlice/utilSlice";
import { resetEditorTracker } from "../../../../store/features/ezEditorTracker/ezEditorTrackerSlice";

const MenuContent = () => {
  const dispatch = useDispatch();
  const { search_form_page_access, active_menu } = useSelector((state) => state.searchFormData);
  const { count_form_page_access } = useSelector((state) => state.countData);
  function onNavigateToForm() {
    //clearing all states here ...
    if (search_form_page_access) {
      dispatch(handleSearchMainAccess(true));
      dispatch(resetStateData());
    } else if (count_form_page_access) {
      dispatch(handleCountMainAccess(true));
      dispatch(resetutilData());
      dispatch(resetUserTab());
    } else {
      dispatch(resetutilData());
      dispatch(resetStateData());
      dispatch(resetUserTab());
      dispatch(resetEditorTracker());
    }
  }

  useEffect(() => {
    let activeNav = localStorage.getItem("active_menu");
    if (!activeNav) {
      dispatch(setActiveMenu("count"));
    } else {
      dispatch(setActiveMenu(activeNav));
    }
  }, []);

  return (
    <ul className="menu-item">
      <NavLink
        className={active_menu === ACTIVE_MENU.COUNT ? "active-style navbar-item" : "navbar-item"}
        id="count"
        to="/ez_count"
        onClick={() => [onNavigateToForm(), dispatch(setActiveMenu(ACTIVE_MENU.COUNT))]}
      >
        EZ Count
      </NavLink>

      {/* <NavLink
        className={active_menu === ACTIVE_MENU.TRANSLATE ? "active-style navbar-item" : "navbar-item"}
        id="translate"
        to="/ez_translate"
        onClick={() => [onNavigateToForm(), dispatch(setActiveMenu(ACTIVE_MENU.TRANSLATE))]}
      >
        EZ Translate
      </NavLink> */}

      <NavLink
        className={active_menu === ACTIVE_MENU.FLIP ? "active-style navbar-item" : "navbar-item"}
        id="flip"
        to="/ez_flip"
        onClick={() => [onNavigateToForm(), dispatch(setActiveMenu(ACTIVE_MENU.FLIP))]}
      >
        EZ Flip
      </NavLink>

      <NavLink
        className={active_menu === ACTIVE_MENU.SEARCH ? "active-style navbar-item" : "navbar-item"}
        id="search"
        to="/ez_search"
        onClick={() => [onNavigateToForm(), dispatch(setActiveMenu(ACTIVE_MENU.SEARCH))]}
      >
        EZ Search
      </NavLink>

      <NavLink
        className={active_menu === ACTIVE_MENU.EDITOR ? "active-style navbar-item" : "navbar-item"}
        id="editor"
        to="/ez_editor"
        onClick={() => [onNavigateToForm(), dispatch(setActiveMenu(ACTIVE_MENU.EDITOR))]}
      >
        EZ Editor
      </NavLink>

      <NavLink
        className={active_menu === ACTIVE_MENU.EDITOR_USER_TM ? "active-style navbar-item" : "navbar-item"}
        id="editor_user_tm"
        to="/ez_editor_user_tm"
        onClick={() => [onNavigateToForm(), dispatch(setActiveMenu(ACTIVE_MENU.EDITOR_USER_TM))]}
      >
        EZ Editor User TM
      </NavLink>
      {/* <NavLink
        className="navbar-item"
        activeClassName="is-active"
        to="/profile"
      >
        ez login
      </NavLink> */}

      <NavLink
        className={active_menu === ACTIVE_MENU.EDITOR_ASSIGNMENT_TRACKER ? "active-style navbar-item" : "navbar-item"}
        id="editor_assignment_tracker"
        to="/ez_editor_assignment_tracker"
        onClick={() => [onNavigateToForm(), dispatch(setActiveMenu(ACTIVE_MENU.EDITOR_ASSIGNMENT_TRACKER))]}
      >
        EZ Assignment Tracker
      </NavLink>
    </ul>
  );
};

export default MenuContent;
