export const ACTIVE_TAB = {
  WORD_COUNT_TAB: "WORD_COUNT_TAB",
  FILE_INFO_TAB: "FILE_INFO_TAB",
};

export const ACTIVE_STATUS = {
  SEARCH_PARTIAL: "partial_complete",
  SEARCH_COMPLETED: "search_completed",
  SEARCH_STARTED: "search_started",
  SEARCH_ERROR: "search_error",
};

export const ACTIVE_MENU = {
  COUNT: "count",
  FLIP: "flip",
  TRANSLATE: "translate",
  SEARCH: "search",
  EDITOR: "editor",
  EDITOR_USER_TM: "editor_user_tm",
  EDITOR_ASSIGNMENT_TRACKER: "editor_assignment_tracker",
};
